import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IntegrationCard from '../../../components/Integration/IntegrationCard';
import IntegrationModal from '../../../components/Integration/IntegrationModal';
import { useTranslation } from 'react-i18next';
import { GenericPagedResponse } from '../../../Types';
import { nanoid } from 'nanoid';


type CompanyInfo = {
  id: number,
  logo: string,
  logoFull: string,
  companyName: string,
  companyType: string,
  supportBy: string,
  image: string,
  type: string,
  desc: string,
  companyDesc: string
}
let integrationList = ['All', 'Video Management', 'Locksets', 'Alarm', 'Bluetooth','System Design and Planning']
const IntegrationsList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [integrationCategory, setIntegrationCategory] = useState<string>('All');
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo[]>();
  useEffect(() => {
    let fetchData = async () => {
      setCompanyInfo(undefined)
      let result = await axios.get<GenericPagedResponse<CompanyInfo>>(`api/integration?pageNumer=1&perPage=20&type=${integrationCategory}&culture=${i18n.language}`)
      setCompanyInfo(result.data.results)
    }
    fetchData()

  }, [i18n.language, integrationCategory])
  const [el, setEl] = useState<HTMLElement>();


  function handleSelected(event: React.MouseEvent<HTMLButtonElement>) {
    if (event.currentTarget.parentElement != null) {
      if (el != null) {
        el.className = "dropdown-item"
      }
      setEl(event.currentTarget.parentElement)
      event.currentTarget.parentElement.className = "dropdown-item active"
    }
    let val = Number(event.currentTarget.value)
    setIntegrationCategory(event.currentTarget.value)
  }

  return (
    <>
      <div className="bg-img-start" style={{ backgroundImage: "url('/images/svg/components/card-11.svg')" }}>
        <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
          <div className="w-md-75 w-lg-50 text-center mx-md-auto">
            <h1>{t('integration partners')}</h1>
            <p>Selectively picked to ensure our products offer a high-quality experience for our dealers and their clients.</p>
          </div>
        </div>
      </div>
      <div className="container content-space-1">
        <div className="row col-lg-divider">
          <div className="col-lg-3">
            <div className="navbar-expand-lg">
              <div className="d-grid">
                <button type="button" className="navbar-toggler btn btn-white mb-3" data-bs-toggle="collapse" data-bs-target="#navbarVerticalNavMenuEg1" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarVerticalNavMenuEg1">
                  <span className="d-flex justify-content-between align-items-center">
                    <span className="text-dark">Menu</span>

                    <span className="navbar-toggler-default">
                      <i className="bi-list"></i>
                    </span>

                    <span className="navbar-toggler-toggled">
                      <i className="bi-x"></i>
                    </span>
                  </span>
                </button>
              </div>

              <div id="navbarVerticalNavMenuEg1" className="collapse navbar-collapse">
                <div className="d-grid gap-4 flex-grow-1">
                  <div className="d-grid">
                    <h3 className="dropdown-header"></h3>
                    {
                      integrationList.map(e => {
                        return <a className="dropdown-item" key={nanoid()}>
                          <button className="btn btn-link px-0 py-0" value={e} onClick={handleSelected} style={{ color: "black" }}>
                            {t('integration.'+e)}
                          </button>
                        </a>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <div className="d-flex justify-content-between align-items-center mb-3">
              {/* <a className="link" href="#">View all <i className="bi-chevron-right small ms-1"></i></a> */}
            </div>

            <div className="row gx-3 mx-n2">
              <div className='px-3 pb-3'>
                <b>{integrationCategory}</b>
              </div>
              {
                companyInfo && companyInfo.map(i => (
                  <IntegrationCard key={nanoid()}
                    companyInfo={i}
                    modal={(e, f, d) => <IntegrationModal show={e} onHide={f} company={d} />}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntegrationsList

