import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

import { ReactComponent as Elc004 } from '../../../assets/vendor/duotone-icons/elc/elc004.svg';
import { ReactComponent as Gen001 } from '../../../assets/vendor/duotone-icons/gen/gen001.svg';
import { ReactComponent as Fil030 } from '../../../assets/vendor/duotone-icons/fil/fil020.svg';
import { ReactComponent as Ecm004 } from '../../../assets/vendor/duotone-icons/ecm/ecm004.svg';

import ContactModal from '../../../components/contact/contact form/ContactModal'

import styles from "./DeploymentOption.module.css";
type Props = {}



const ProtectorNetDeploymentOptions: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <Fragment>
              <div className="bg-img-start" style={{ backgroundImage: "url('/images/deployment-options-hero.jpg')" }}>
                 <div className="container content-space-2 content-space-lg-3">
                     <div className="w-md-65 w-lg-40">
                        <div className="mb-2">
                            <h2 className= "h1 text-white mb-2"> {"Deployment Options"} </h2>
                            
                        </div>    
                            <div className="btn btn-light btn-transition rounded-pill border border-white text-white bg-transparent" onClick={()=>setModalShow(true)}>Request Demo</div>
                                <ContactModal whom={"Sales"} show={modalShow} onHide={setModalShow}
                            />
                            
                      </div> 
                 </div>   
            </div>    
            
            <div className="container content-space-2 content-space-lg-3">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h2>Deployment options that fit your needs.</h2>
                    </div>

                    <div className="col-lg-6">
                        <p>When it comes to deploying your Hartmann access control system, there are multiple options to choose from. The deployment option you choose will depend on a number of factors, including the size of your organization, your budget, and the level of control and security you require.  No matter what the requirements, Hartmann has a deployment option that will meet your needs.</p>
                    </div>
                </div>
            </div>
            <div id="learnSection" className="container ">
                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    {/* <h2 className="h1">Why Front?</h2> */}
                    <p>At Hartmann, we offer a range of deployment options for our Protector.Net and Odyssey access control software.</p>
                </div>

                <div className="w-lg-75 mx-md-auto">
                    <ul className="step step-md step-centered mb-md-9">
                        <li className="step-item">
                            <div className="step-content-wrapper">
                                <span className="step-icon step-icon-sm">
                                    <Elc004 className={styles.ffb800} />
                                </span>
                                <div className="step-content">
                                    <h5 className="step-title">Embedded</h5>
                                </div>
                            </div>
                        </li>

                        <li className="step-item">
                            <div className="step-content-wrapper">
                                <span className="step-icon step-icon-sm">
                                    <Gen001 className={styles.ffb800} />
                                </span>
                                <div className="step-content">
                                    <h5 className="step-title">On-Site</h5>
                                </div>
                            </div>
                        </li>

                        <li className="step-item">
                            <div className="step-content-wrapper">
                                <span className="step-icon step-icon-sm">
                                    <Fil030 className={styles.ffb800} />
                                </span>
                                <div className="step-content">
                                    <h5 className="step-title">Cloud</h5>
                                </div>
                            </div>
                        </li>

                        <li className="step-item">
                            <div className="step-content-wrapper">
                                <span className="step-icon step-icon-sm ">
                                    <Ecm004 className={styles.ffb800} />
                                </span>
                                <div className="step-content">
                                    <h5 className="step-title">Dealer hosted</h5>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div>
                <div className="overflow-hidden">
                    <div className="container content-space-2 content-space-t-lg-3">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 mb-9 mb-lg-0">
                                <div className="mb-5">
                                    <h2>Embedded Deployment</h2>
                                    <p>The HC Atom is a compact and convenient Linux-based embedded appliance that is perfect for small to medium-sized businesses. Designed specifically for Protector.net, this deployment option offers a plug-and-play solution, making it easy to set up and maintain, without the need for additional hardware. With its web-based management interface and easy backup/restore and system upgrade capabilities, the HC Atom is a powerful solution for businesses with 40 doors or less.</p>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <img className="device-mobile-img" src="/images/DeploymentOptions/embedded.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                                <div className="mb-5">
                                    <h2>On-Site Deployment</h2>
                                    <p>For businesses that prefer to keep their data on-site, the on-site deployment option provides the full potential of the Protector.Net and Odyssey access control systems. With all data stored on a server located at your location, this deployment option is typically the most cost-effective solution. This option allows you to take full advantage of the system's advanced features while maintaining complete control over your data.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 order-lg-1">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <img className="device-mobile-img" src="/images/DeploymentOptions/on-site.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 mb-9 mb-lg-0">
                                <div className="mb-4">
                                    <h2>Cloud Deployment</h2>
                                    <p>For those who want the convenience of a cloud-based solution, Hartmann offers the cloud deployment option. This option allows you to access your access control system from anywhere, without any additional configuration or server on site. With Hartmann taking care of all maintenance, you can enjoy a hassle-free experience and rest assured that your system will always be up-to-date.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 order-lg-1">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <img className="device-mobile-img" src="/images/DeploymentOptions/cloud.png" alt="Image Description" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-hidden">
                    <div className="container content-space-t-2 content-space-t-lg-3 content-space-b-lg-2">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                                <div className="mb-5">
                                    <h2>Dealer Hosted Deployment</h2>
                                    <p>The dealer-hosted deployment option is similar to the cloud deployment option, with one key difference: a dealer gets recurring revenue. From the end-user perspective, this option provides the same level of convenience as the cloud deployment option, allowing you to access your access control system from anywhere, without any additional configuration or server on site.
                                        This option allows you to take full advantage of the system, ensuring that you get the most out of your investment.</p>
                                </div>
                            </div>

                            <div className="col-lg-6 order-lg-1">
                                <div className="position-relative mx-auto" data-aos="fade-up">
                                    <img className="device-mobile-img" src="/images/DeploymentOptions/dealerhosted.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="w-lg-75 text-center mx-auto mb-5 mb-sm-9">
                <h2 className="h1">{t('Deployment.Deployment Features')}</h2>
                <p>{t('Deployment.Deployment Description')}</p>
                
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-12 col-lg-6">
                    <div className="table-responsive-lg  w-lg-75 mx-lg-auto" >
                        <table className="table table-lg table-striped table-bordered table-nowrap table-vertical-border-striped">
                            <thead className="table-text-center">
                                <tr>
                                    <th scope="col" style={{ width: '40%' }}></th>
                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('Deployment.Cloud')}</span>
                                        {/* <span className="d-block text-muted small">$0/mon</span> */}
                                    </th>
                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('Deployment.Embedded')}</span>
                                        {/* <span className="d-block text-muted small">$0/mon</span> */}
                                    </th>

                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('Deployment.On-Site')}{/*<span className="badge bg-warning text-dark rounded-pill ms-1">Hot</span>*/}</span>
                                        {/* <span className="d-block text-muted small">$60/mon</span> */}
                                    </th>
                                    <th scope="col" style={{ width: '20%' }}>
                                        <span className="d-block">{t('Deployment.Dealer-Hosted')}{/*<span className="badge bg-warning text-dark rounded-pill ms-1">Hot</span>*/}</span>
                                        {/* <span className="d-block text-muted small">$60/mon</span> */}
                                    </th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Elevators')}</th>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Action Plans')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Maps')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                  
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Profile Pictures')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.LDAP')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-check-square-fill text-success"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.User Limit')}</th>
                                    <td className="table-text-center">2000</td>
                                    <td className="table-text-center">4000</td>
                                    <td className="table-text-center">{t('Deployment.Unlimited')}</td>
                                    <td className="table-text-center">{t('Deployment.Unlimited')}</td>
                                   
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Badge Printing')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                   
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.DSC')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                   
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Schlage')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                   
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.Assa Abloy')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                   
                                </tr>

                                <tr>
                                    <th scope="row" className="text-dark">{t('Deployment.ONVIF VMS')}</th>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                    <td className="table-text-center"><i className="bi bi-x-square-fill text-danger"></i></td>
                                   
                                </tr>

                            </tbody>
                        </table>
                       
                    </div>
                </div>
            </div>
        </Fragment>
        
    )
}
export default ProtectorNetDeploymentOptions;
